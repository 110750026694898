export const UserTypes = {
    ADM_MASTER: "Administrador master",
    ADM_MESA: "Administrador de mesa",
    COLABORADOR: "Colaborador",
    GD : "Gerente distrital",
    REPRESENTANTE: "Representante",
    MEDICO_APROVADOR: "Médico aprovador",
    CORPORATIVO: "Corporativo",
    MEDICO_SOLICITANTE: "Médico solicitante",
    
    UserWithMesa: function(){
        return [this.ADM_MESA, this.COLABORADOR];
    }

};